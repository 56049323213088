import React, { useState } from "react";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import ModalVideo from "react-modal-video";
import Hero from "./../components/Hero16x9";
import TextImageSlider from "./../components/Home/TextImageSlider";
import Content from "./../components/Content";
import TextBlock from "./../components/TextBlock";
import ISPNavigation from "../components/ISP/ISPNavigation";
import FlightBlock from "../components/ISP/FlightBlock";
import Performance from "../components/Home/Performance";
import Layout from "./../components/Layout";

const Page = styled.div``;

export default ({
  data: { datoCmsOneConnectivity: content, infoSlider, home },
  location
}) => {
  console.log("content", content);
  const [isOpen, setOpen] = useState(false);
  return (
    <Layout location={location}>
      <Page>
        <ModalVideo
          channel="custom"
          autoplay
          isOpen={isOpen}
          url={content.video.url}
          onClose={() => setOpen(false)}
        />
        <Hero src={content.headerImage.url} />

        <Content bg="#e20074">
          <TextBlock
            color="white"
            title={content.pageTitle}
            copy={content.pageIntro}
          />
        </Content>
        {content.videoPoster && content.videoUrl && (
          <div
            style={{
              padding: "32px 0",
              cursor: "pointer",
              position: "relative"
            }}
            onClick={() => setOpen(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 17.804 17.804"
              style={{
                position: "absolute",
                height: "60%",
                enableBackground: "new 0 0 17.804 17.804",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                opacity: 0.4
              }}
              xmlSpace="preserve"
            >
              <path d="M2.067.043a.4.4 0 0 1 .426.042l13.312 8.503a.41.41 0 0 1 .154.313c0 .12-.061.237-.154.314L2.492 17.717a.402.402 0 0 1-.25.087l-.176-.04a.399.399 0 0 1-.222-.361V.402c0-.152.086-.295.223-.359z" />
            </svg>
            <Hero src={content.videoPoster.url} />
          </div>
        )}
        <Content bg="#efefef">
          <TextBlock
            color="regular"
            title={
              <span style={{ color: "#e20074" }}>{content.videoTitle}</span>
            }
            copy={content.videoIntro}
          />
        </Content>
        <TextImageSlider content={infoSlider.edges} />
        {/*
      <Content bg="#e20074" style={{ paddingBottom: 48 }}>
        <TextBlock
          color="magenta"
          maxWidth="1140px"
          title={content.ispIntroTitle}
          copy={content.ispIntroCopy}
        />
        <ISPNavigation />
        {content.flightBlocks
          ? content.flightBlocks.map((b, k) => (
              <FlightBlock content={b} key={k} />
            ))
          : null}
      </Content>
      */}

        {/* <Performance */}
        {/*   title={home.airlinesBlock[0].title} */}
        {/*   subline={home.airlinesBlock[0].subline} */}
        {/*   intro={home.airlinesBlock[0].intro} */}
        {/*   airlines={home.airlinesBlock[0].airlinesList} */}
        {/* /> */}
        {/*
      <Content bg="#ededed">
        <TextBlock title={content.outroTitle} copy={content.outroCopy} />
      </Content>
      */}
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query OneConnectivityQuery {
    datoCmsOneConnectivity {
      headerImage {
        url
      }
      pageTitle
      pageIntro
      videoTitle
      videoIntro
      videoPoster {
        url
      }
      videoUrl
      video {
        url
      }
    }
    infoSlider: allDatoCmsOneConnectivitySlider {
      edges {
        node {
          headline
          body
          image {
            url
          }
        }
      }
    }
    home: datoCmsHomePage {
      airlinesBlock {
        title
        subline
        intro
        airlinesList {
          url
          alt
          width
          height
        }
      }
    }
  }
`;
