import React from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import H2 from "../typo/H2";
import H3 from "../typo/H3";
import P from "../typo/P";

const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Text = styled.div`
  @media screen and (min-width: 768px) {
    flex: 1 0 50%;
    text-align: left;
    padding-left: 32px;
  }
`;
const ImgWrap = styled.div`
  margin-bottom: 32px;
  @media screen and (min-width: 768px) {
    flex: 1 0 50%;
    white-space: nowrap;
  }
`;

const TextImageSlider = ({ content, className }) => (
  <div className={`container ${className}`}>
    <section className="text-gallery textimage-gallery">
      <Carousel
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        infiniteLoop={true}
        autoPlay={false}
        infiniteLoop={true}
      >
        {content.map((c, k) => (
          <div key={k}>
            <Wrap>
              {c.node.image ? (
                <ImgWrap>
                  <img src={c.node.image.url} />
                </ImgWrap>
              ) : null}
              <Text>
                {c.node.headline ? <h3>{c.node.headline}</h3> : null}
                {c.node.body ? <p>{c.node.body}</p> : null}
              </Text>
            </Wrap>
          </div>
        ))}
      </Carousel>
    </section>
  </div>
);

export default TextImageSlider;
