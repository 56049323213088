import React from "react";
import styled from "styled-components";

const Hero = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  background-size: cover;
  background-position: 50%;
  border-top: 1px solid #e20074;
`;

export default ({ src }) => <Hero style={{ backgroundImage: `url(${src})` }} />;
